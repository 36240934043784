import axios from "axios";
import camelcaseKeys from "camelcase-keys";

const DEFAULT_OFFSET = 100;

export const getOrderList = async (
  shopUrl: string,
  searchMethod: "ORDER_NO" | "PHONE_NO",
  orderGroupNo?: string,
  name?: string,
  paginationOptions = { offset: DEFAULT_OFFSET, page: 1 }
) => {
  const commonParams = {
    store_url_path: shopUrl,
    ...paginationOptions,
  };

  let requestParams: any = commonParams;
  let requestHeaders = {};
  if (searchMethod === "ORDER_NO") {
    // 주문번호 + 이름 기반 조회
    requestParams = {
      ...requestParams,
      order_group_no: orderGroupNo,
      name,
    };
  } else if (searchMethod === "PHONE_NO") {
    // 휴대폰 번호 기반 조회
    const accessToken = localStorage.getItem("access_token");

    requestHeaders = { Authorization: `Bearer ${accessToken}` };
  }

  const response = await axios.get(
    `${process.env.VUE_APP_BACKEND_SERVER}/v2/order/list/shp/`,
    {
      params: requestParams,
      headers: requestHeaders,
    }
  );
  return camelcaseKeys(response, { deep: true });
};

export const requestAuthCode = async (phoneNo: string) => {
  const res = await axios.post(
    `${process.env.VUE_APP_BACKEND_SERVER}/commerce/auth/code-issue/shp/`,
    {
      phone: phoneNo,
    }
  );
  return camelcaseKeys(res.data, { deep: true });
};

export const checkAuthCode = async (
  phoneNo: string,
  code: string,
  path: string
) => {
  const res = await axios.post(
    `${process.env.VUE_APP_BACKEND_SERVER}/commerce/auth/code-verify/shp/`,
    {
      phone: phoneNo,
      code,
      store_url_path: path,
    }
  );
  return camelcaseKeys(res.data, { deep: true });
};

export const requestDeliveryInfo = async (orderNo: string) => {
  const res = await axios.post(
    `${process.env.VUE_APP_BACKEND_SERVER}/delivery/check-shipment/shp/`,
    { order_no: orderNo }
  );
  return camelcaseKeys(res.data, { deep: true });
};

export const requestAddressChange = async (payload: any) => {
  const res = await axios.post(
    `${process.env.VUE_APP_BACKEND_SERVER}/delivery/change-address/shp/`,
    payload
  );
  return res;
};
