import * as Sentry from "@sentry/vue";
import { AxiosError, HttpStatusCode } from "axios";

class CustomNameAxiosError extends AxiosError {
  constructor(error: AxiosError) {
    const { config, code, request, response } = error;

    super(error.message, code, config, request, response);
    this.name = error.name;

    if (response != null) {
      const statusText = CustomNameAxiosError.getStatusText(response.status);
      const url = this.getRequestUrl();
      const name = `API${statusText}Error`;
      const title = `[${response.status}] ${name}: ${url}`;
      this.name = title;
    }
  }

  private static getStatusText(status: HttpStatusCode) {
    const index = Object.values(HttpStatusCode).indexOf(status);
    return Object.keys(HttpStatusCode)[index];
  }

  private getRequestUrl() {
    const UNKNOWN_REQUEST_URL = "Unknown Request URL";
    if (this.config == null) {
      return UNKNOWN_REQUEST_URL;
    }
    const { baseURL, url } = this.config;
    if (baseURL && url) {
      if (url.startsWith(baseURL)) {
        return url;
      }
      return baseURL + url;
    }
    return url ?? UNKNOWN_REQUEST_URL;
  }
}

export const captureAxiosError = (error: AxiosError) => {
  Sentry.withScope((scope) => {
    scope.setTag("type", "network");
    scope.setLevel("error");

    if (error.config) {
      const { method, url, params, data, headers } = error.config;
      scope.setContext("API Request Detail", {
        method,
        url,
        params,
        data,
        headers: headers.toJSON(),
      });
    }

    if (error.response) {
      const { status, data, headers } = error.response;
      scope.setContext("API Response Detail", {
        status,
        data,
        headers: JSON.stringify(headers),
      });
    }

    Sentry.captureException(new CustomNameAxiosError(error));
  });
};
