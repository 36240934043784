import type {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { captureAxiosError } from "@/utils/sentry";

export const BASE_API_URL = process.env.VUE_APP_BACKEND_SERVER || "";
export const BASE_ASYNC_URL = process.env.VUE_APP_ASYNC_BACKEND_SERVER || "";
export const BASE_API_DEBUG_URL =
  process.env.VUE_APP_DEBUG_BACKEND_SERVER || "";

export const requestHandler = async (config: InternalAxiosRequestConfig) => {
  return config;
};

export const responseHandler = (response: AxiosResponse) => {
  return response;
};

export const errorHandler = (error: AxiosError) => {
  captureAxiosError(error);
  return Promise.reject(error);
};
