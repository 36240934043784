import { computed, ref, Ref } from "@vue/composition-api";

import { SelectStep } from "../Types";
import { SELECT_STEP } from "../constants";
import {
  SelectedProduct,
  Option,
  ProductDetail,
  SelectedOption,
} from "@/components/Product/Types";

export default (product: Ref<ProductDetail | undefined>) => {
  const selectStep = ref<SelectStep>(SELECT_STEP.view);

  const selectedProducts = ref<SelectedProduct[]>([]);

  const hasSameOption = (
    currentProduct: SelectedProduct,
    selectedProducts: SelectedProduct[]
  ) => {
    // TODO: 추가 옵션 처리 추가

    // const curLastBaseOptionIdx =
    //   currentProduct.options.baseOptions.length > 0
    //     ? currentProduct.options.baseOptions.slice(-1)[0].optionIdx
    //     : null;

    // if (curLastBaseOptionIdx == null || selectedProducts.length === 0) {
    //   return false;
    // }

    // const duplicatedProduct = selectedProducts.filter((product) => {
    //   const targetLastBaseOptionIdx =
    //     product.options.baseOptions.length > 0
    //       ? product.options.baseOptions.slice(-1)[0].optionIdx
    //       : null;

    //   if (targetLastBaseOptionIdx == null) {
    //     return false;
    //   }

    //   return targetLastBaseOptionIdx === curLastBaseOptionIdx;
    // });

    let hasSameOption = false;

    selectedProducts.forEach((selectedProduct) => {
      if (hasSameOption) {
        return;
      }
      for (let i = 0; i < selectedProduct.options.length; i++) {
        if (
          selectedProduct.options[i].optionIdx !=
          currentProduct.options[i].optionIdx
        ) {
          return;
        }
      }

      hasSameOption = true;
    });

    return hasSameOption;
  };

  const selectProduct = (options: Option[]) => {
    if (!product.value) {
      return;
    }
    const selectedOptions: SelectedOption[] = options.map((option) => {
      return {
        optionIdx: option.idx,
        type: option.type,
        name: option.name,
        addPrice: option.addPrice,
        text: null,
      };
    });

    const selectedProduct = {
      productNo: product.value.productNo,
      productName: product.value.productName,
      brandName: product.value.brandName,
      price: product.value.price,
      marketPrice: product.value.marketPrice,
      thumbnailImg: product.value.thumbnailImg,
      options: selectedOptions,
      qty: 1,
      optionType: product.value.options.optionType,
      isAbroad: product.value.isAbroad,
      sellType: product.value.sellType,
      allowAccountPayment: product.value.allowAccountPayment,
    };

    if (hasSameOption(selectedProduct, selectedProducts.value)) {
      throw new Error("Dupicated Options");
    } else {
      selectedProducts.value.unshift(selectedProduct);
      selectStep.value = "MENU";
    }
  };

  const deleteProduct = (idx: number) => {
    if (selectedProducts.value.length <= 0) {
      return;
    }

    selectedProducts.value.splice(idx, 1);
  };

  const changeProductQuantity = (idx: number, quantity: number) => {
    if (selectedProducts.value[idx]) {
      selectedProducts.value[idx].qty = quantity;
    }
  };

  const totalQuantity = computed(() => {
    const total = selectedProducts.value.reduce((acc, cur) => {
      return acc + cur.qty;
    }, 0);

    return total;
  });

  const totalPrice = computed(() => {
    const priceList = selectedProducts.value.map((product) => {
      const baseOptionsSum = product.options.reduce(
        (acc, option) => acc + option.addPrice,
        0
      );
      // const additionalOptionsSum = product.options.additionalOptions.reduce(
      //   (acc, option) => acc + option.addPrice,
      //   0
      // );
      const addedPrice = (product.price + baseOptionsSum) * product.qty;
      return addedPrice;
    });

    const total = priceList.reduce((acc, price) => acc + price, 0);

    return total;
  });

  return {
    selectStep,
    selectedProducts,
    totalQuantity,
    totalPrice,

    selectProduct,
    deleteProduct,
    changeProductQuantity,
  };
};
